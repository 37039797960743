<template>
<div>
    <div class="row">
    <div class="col-sm-3">
    <label class="txt-title-input">First Name</label>
    <div class="form-group" style="height: 10px">
        {{ profile.firstName }}
    </div>
    </div>
    <div class="col-sm-3">
    <label class="txt-title-input">Last Name</label>
    <div class="form-group" style="height: 10px">
        {{ profile.lastName }}
    </div>
    </div>
    <div class="col-sm-3">
    <label class="txt-title-input">Gender</label>
    <div class="form-group" style="height: 10px">
        {{ profile.gender }}
    </div>
    </div>
    <div class="col-sm-3">
    <label class="txt-title-input">Date of Birth</label>
    <div class="form-group" style="height: 10px">
        {{ computedDateOfBirthDateFormatted }}
    </div>
    </div>
</div>

<br />
<h5>Contact details</h5>
<div class="row">
    <div class="col-sm-4">
    <label><b>Address</b></label>
    <div class="form-group" style="height: 10px">
        {{ profile.address }}
    </div>
    </div>
    <div class="col-sm-4">
    <label class="txt-title-input">Email</label>
    <div class="form-group" style="height: 10px">
        {{ profile.email }}
    </div>
    </div>
    <div class="col-sm-4">
    <label class="txt-title-input">Contact Number</label>
    <div class="form-group" style="height: 10px">
        {{ profile.contactNumber }}
    </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-sm-6">
    <label class="txt-title-input">Notes</label>
    <div class="form-group" style="height: 10px">
        {{ profile.notes }}
    </div>
    </div>
</div>
</div>
</template>

<script>

import moment from "moment";

export default {
    props: {
        profile: Object
    },
    computed: {
        computedDateOfBirthDateFormatted() {
            return this.profile.birthDate ? moment(this.profile.birthDate).format("DD/MM/YYYY") : "";
        },
  },
}
</script>
