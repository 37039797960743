<template>
<div>
    <div class="row">
        <div class="col-sm-4">
            <label class="txt-title-input">Status Residency</label>
            <div class="form-group" style="height: 10px">
            {{ taxInfo.statusResidency }}
            </div>
        </div>
        <div class="col-sm-4">
            <div v-if="!isExemption">
            <label class="txt-title-input">Tax File Number</label>
            <div class="form-group" style="height: 10px">
                {{ taxInfo.taxNumber }}
            </div>
            </div>
            <div v-else>
            <label class="txt-title-input">Tax File Number</label>
            <div class="form-group" style="height: 10px">
                {{ taxInfo.tfnExemption }}
            </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
    <div class="col-12">
        <h6>Additional Information</h6>
        <div class="form-check" style="margin: 5px 0">
        <input
            v-model="taxInfo.claimTaxFreeThreshold"
            name="claimTaxFreeThreshold"
            class="
            form-check-input
            ng-pristine ng-untouched ng-valid ng-empty
            "
            type="checkbox"
            value=""
            :disabled="true"
        />
        <label class="form-check-label" for="claimTaxFreeThreshold">
            Claim tax free threshold
        </label>
        </div>
        <div class="form-check" style="margin: 5px 0">
        <input
            v-model="taxInfo.hasHecsOrHelpDebt"
            name="hasHecsOrHelpDebt"
            class="
            form-check-input
            ng-pristine ng-untouched ng-valid ng-empty
            "
            type="checkbox"
            value=""
            :disabled="true"
        />
        <label class="form-check-label" for="hasHecsOrHelpDebt">
            <!-- Has HECS or HELP debt -->
            Has study and training support loan debt 
            <i class="fa fa-info-circle" title="Any of the following-
            Higher Education Loan Program (HELP) debt
            VET Student Loan (VSL) debt
            Financial Supplement (FS) debt
            Student Start-up Loan (SSL) debt (including ABSTUDY SSL debts)
            Trade Support Loan (TSL) debt"></i>
        </label>
        </div>
    </div>
    </div>
    <div class="row">
    <div class="col-sm-4">
        <div v-if="taxInfo.hasTaxTemplate">
        <label class="txt-title-input">Overwrite tax</label>
        <div class="form-group" style="height: 10px">
            {{ taxInfo.taxTemplate }}
        </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
export default {
    name: "EmployeeTaxPanel",
    props: {
        taxInfo: Object
    }
}
</script>
